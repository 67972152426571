import React, { useState, MouseEvent } from "react";
import { Card, Row, Button, Pagination, Table, Tooltip, Modal } from "antd";
import { AppDispatch } from "stores";
import { useSelector, useDispatch } from "react-redux";
import { get, remove } from "stores/part/actions/part";
import {
  EditOutlined,
  MinusCircleOutlined,
  UnorderedListOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import UpdatePartDialog from "pages/mtn/part/sections/tabs/part/table-pagination/dialog/update";
import OutPartDialog from "pages/mtn/part/sections/tabs/part/table-pagination/dialog/out";
import DetailPartDialog from "pages/mtn/part/sections/tabs/part/table-pagination/dialog/detail";
import { toggleUpdateDialog, toggleOutDialog, setPageSize } from "stores/part";
import styled from "styled-components";
// import dayjs from "dayjs";

const StyledTable = styled(Table)`
  && tbody > tr:hover > td {
    cursor: pointer;
  }
`;

const TablePagination = () => {
  const [currentRecord, setCurrentRecord] = useState<any>({});
  const {
    isLoadingData,
    currentPage,
    totalData,
    search,
    pageSize,
    datas,
    repair,
    lifeTime,
  } = useSelector((state: any) => state.part);

  const role = useSelector((state: any) => state?.auth?.lastLogin?.role?.id);

  const newDatas = datas?.map((item: any, index: number) => {
    return {
      ...item,
      no: pageSize * (currentPage - 1) + index + 1,
    };
  });

  const dispatch = useDispatch<AppDispatch>();

  const handleDialogUpdate = (event: MouseEvent, record: any) => {
    event.stopPropagation();
    setCurrentRecord(record);
    dispatch(toggleUpdateDialog(true));
  };

  const handleOut = (event: MouseEvent, dataRecord: any) => {
    event.stopPropagation();
    setCurrentRecord(dataRecord);
    dispatch(toggleOutDialog(true));
  };

  const [toggleDetail, setToggleDetail] = useState(false);

  const handlePaginate = async (page: number, pageSize: number) => {
    dispatch(setPageSize(pageSize));
    const params: any = {
      page: page,
      per_page: pageSize,
      keyword: search,
      is_repair: repair,
    };

    if (search) {
      params.keyword = search;
    }

    if (lifeTime) {
      params.life_time = lifeTime ? lifeTime.format("YYYY-MM-DD") : "";
    }

    await dispatch(get(params)).unwrap();
  };

  const [modal, contextHolder] = Modal.useModal();
  const handleRemove = (dataRecord: any) => {
    modal.confirm({
      title: "Confirm",
      content: "Are sure to remove part?",
      onOk: async () => {
        await dispatch(remove(dataRecord?.id)).unwrap();
        const params: any = {
          page: currentPage,
          per_page: pageSize,
          keyword: search,
          is_repair: repair,
        };

        if (search) {
          params.keyword = search;
        }

        if (lifeTime) {
          params.life_time = lifeTime ? lifeTime.format("YYYY-MM-DD") : "";
        }

        await dispatch(get(params)).unwrap();
      },
    });
  };

  const columns: any = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 70,

      align: "center",
    },
    {
      title: "ID Part",
      dataIndex: "part_number",
      key: "id_part",
      width: 150,
    },
    {
      title: "Name",
      dataIndex: "part_name",
      key: "name",
      width: 150,
    },
    // {
    //   title: "Life Time",
    //   dataIndex: "life_time",
    //   key: "life_time",
    //   width: 150,
    //   render: (record: string) =>
    //     record ? dayjs.utc(record).format("DD/MM/YYYY") : "-",
    // },
    {
      title: "Countdown Life Time",
      dataIndex: "countdown_life_time",
      key: "countdown_life_time",
      width: 150,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 150,
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
      width: 150,
    },
    {
      title: "Rank",
      dataIndex: "range",
      key: "range",
      width: 150,
      render: (text: string) => (text ? text : "-"),
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      width: 150,
    },
    {
      title: "Maker",
      dataIndex: "maker_name",
      key: "maker",
      width: 150,
    },
    {
      title: "Supplier",
      dataIndex: "supplier_name",
      key: "supplier",
      width: 150,
    },
    {
      title: "Line",
      dataIndex: "line_name",
      key: "line_name",
      width: 150,
    },
    {
      title: "Unit",
      dataIndex: "unit_name",
      key: "unit",
      width: 150,
    },
    {
      title: "Store",
      dataIndex: "store_code",
      key: "store",
      width: 150,
    },
    {
      title: "Options",
      key: "options",
      width: role !== 11 ? 180 : 80,
      fixed: "right",
      render: (dataRecord: any) => {
        return (
          <Row justify="space-between">
            <Tooltip title="Detail">
              <Button
                onClick={() => {
                  setCurrentRecord(dataRecord);
                  setToggleDetail(true);
                }}
                type="primary"
                shape="circle"
                icon={<UnorderedListOutlined />}
              />
            </Tooltip>
            {role !== 11 && (
              <>
                <Tooltip title="Edit">
                  <Button
                    disabled={role === 11}
                    onClick={(event) => handleDialogUpdate(event, dataRecord)}
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                  />
                </Tooltip>

                <Tooltip title="Remove">
                  <Button
                    disabled={role === 11}
                    onClick={() => handleRemove(dataRecord)}
                    type="primary"
                    danger
                    shape="circle"
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>

                <Tooltip title="Part Out">
                  <Button
                    disabled={role === 11}
                    onClick={(event) => handleOut(event, dataRecord)}
                    type="primary"
                    danger
                    shape="circle"
                    icon={<MinusCircleOutlined />}
                  />
                </Tooltip>
              </>
            )}
          </Row>
        );
      },
    },
  ];

  return (
    <Card>
      {contextHolder}
      <UpdatePartDialog dataRecord={currentRecord} />
      <OutPartDialog dataRecord={currentRecord} />
      <DetailPartDialog
        toggleDetail={toggleDetail}
        setToggleDetail={setToggleDetail}
        dataRecord={currentRecord}
      />

      <StyledTable
        rowKey={(record: any) => record.id}
        loading={isLoadingData}
        columns={columns}
        dataSource={newDatas}
        pagination={false}
        scroll={{ x: 1500 }}
      />
      <Row className="mt-5" justify="end">
        <Pagination
          current={currentPage}
          total={totalData}
          showSizeChanger
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page, pageSize) => handlePaginate(page, pageSize)}
        />
      </Row>
    </Card>
  );
};

export default TablePagination;
