import { useCallback, useMemo } from "react";
import { Card, Input, Row, Col, Button, DatePicker, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DialogCreateStore from "pages/mtn/part/sections/action-table/dialog/create";
import {
  toggleAddDialog,
  setSearch,
  setActiveTab,
  setLifeTime,
} from "stores/part";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "stores";
import * as partAction from "stores/part/actions/part";
import PartService from "services/mtns/part/PartService";
import debounce from "lodash/debounce";
import downloader from "utils/downloader";

const ActionTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { search, pageSize, isLoadingSearch, repair, lifeTime } = useSelector(
    (state: any) => state.part
  );
  const role = useSelector((state: any) => state?.auth?.lastLogin?.role?.id);

  const sendBackendRequest = useCallback((value: any) => {
    dispatch(partAction.search(value)).unwrap();
  }, []);

  const debouncedSendRequest = useMemo(() => {
    return debounce(sendBackendRequest, 500);
  }, [sendBackendRequest]);

  const handleChange = (e: any) => {
    const value = e.target.value;
    const params = {
      page: 1,
      per_page: pageSize,
      keyword: value,
      is_repair: repair,
      life_time: lifeTime ? lifeTime.format("YYYY-MM-DD") : "",
    };
    dispatch(setSearch(value));
    debouncedSendRequest(params);
  };

  const onSearch = () => {
    const params = {
      page: 1,
      per_page: pageSize,
      keyword: search,
      is_repair: repair,
      life_time: lifeTime ? lifeTime.format("YYYY-MM-DD") : "",
    };

    sendBackendRequest(params);
  };

  const onSearchLifeTime = (val: any) => {
    dispatch(setLifeTime(val));
    const params = {
      page: 1,
      per_page: pageSize,
      keyword: search,
      is_repair: repair,
      life_time: val ? val.format("YYYY-MM-DD") : "",
    };
    dispatch(partAction.search(params)).unwrap();
  };

  const handleDownload = async () => {
    try {
      const params: Record<string, string> = {};
      if (search) {
        params.keyword = search;
      }

      if (lifeTime) {
        params.life_time = lifeTime ? lifeTime.format("YYYY-MM-DD") : "";
      }

      const { data } = await PartService.donwloadPart(params);
      downloader(data, "Part", ".xlsx");
    } catch (err) {
      message.error("Download Error!");
      console.log(err);
    }
  };

  return (
    <>
      <Card className="mb-3">
        <Row justify="space-between" gutter={10}>
          <Col span={6}>
            <Input.Search
              loading={isLoadingSearch}
              value={search}
              placeholder="Search"
              onChange={handleChange}
              onSearch={onSearch}
              enterButton
            />
          </Col>
          <Col span={6}>
            <DatePicker
              format={"DD/MM/YYYY"}
              style={{ width: "70%" }}
              placeholder="Search Life Time"
              value={lifeTime}
              onChange={onSearchLifeTime}
            />
          </Col>
          <Col span={12}>
            {role !== 11 && (
              <>
                <Row justify="end">
                  <Button
                    disabled={role === 11}
                    onClick={handleDownload}
                    type="primary"
                    icon={<PlusOutlined />}
                    className="mr-3"
                  >
                    Download
                  </Button>
                  <Button
                    disabled={role === 11}
                    onClick={() => dispatch(toggleAddDialog(true))}
                    type="primary"
                    icon={<PlusOutlined />}
                  >
                    Create
                  </Button>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Card>
      <DialogCreateStore />
    </>
  );
};

export default ActionTable;
