import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { AppDispatch } from "stores";
import { toggleAddDialog } from "stores/part";
import { get, create } from "stores/part/actions/part";
import useDialogPart from "pages/mtn/part/sections/hooks/useDialogPart";

const useDialogCreate = () => {
  const {
    partOption,
    form,
    fileImg,
    imgBase64,
    imgRef,
    handleRefImg,
    handleUpload,
    openModal,
    openModalCreate,
    setFileImg,
    setImgBase64,
  } = useDialogPart();

  const { dialogAdd, isLoading, pageSize } = useSelector(
    (state: any) => state.part
  );
  const dispatch = useDispatch<AppDispatch>();
  const onCancel = () => {
    form.resetFields();
    dispatch(toggleAddDialog(false));
    setFileImg(null);
    setImgBase64("");
  };

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      await dispatch(
        create({
          ...values,
          file: fileImg,
          one_used: values.one_used ? 1 : 0,
          // life_time: values.life_time.format("YYYY-MM-DD"),
        })
      ).unwrap();
      const params = {
        page: 1,
        per_page: pageSize,
      };
      await dispatch(get(params)).unwrap();
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (dialogAdd) {
      openModalCreate();
    }
  }, [dialogAdd]);

  return {
    dialogAdd,
    partOption,
    isLoading,
    form,
    fileImg,
    imgBase64,
    imgRef,
    onCancel,
    onSubmit,
    handleRefImg,
    handleUpload,
  };
};

export default useDialogCreate;
