import { useCallback, useMemo } from "react";
import { Card, Input, Row, Col, Button, DatePicker, message } from "antd";
import { setSearch, setDate } from "stores/mtnInventory/recap";
import { RetweetOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "stores";
import * as storeAction from "stores/mtnInventory/recap/actions/recap";
import debounce from "lodash/debounce";
import RecapServices from "services/mtns/inventory/RecapServices";
import downloader from "utils/downloader";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const { RangePicker } = DatePicker;

const ActionTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { search, pageSize, isLoadingSearch, date } = useSelector(
    (state: any) => state.mtnRecapInv
  );

  const sendBackendRequest = useCallback((value: string) => {
    dispatch(storeAction.search(value)).unwrap();
  }, []);

  const debouncedSendRequest = useMemo(() => {
    return debounce(sendBackendRequest, 500);
  }, [sendBackendRequest]);

  const handleChange = (e: any) => {
    const value = e.target.value;
    const params: any = {
      page: 1,
      per_page: pageSize,
      keyword: value,
      start_date: date ? dayjs(date[0]).format("YYYY-MM-DD") : "",
      end_date: date ? dayjs(date[1]).format("YYYY-MM-DD") : "",
    };
    dispatch(setSearch(value));
    debouncedSendRequest(params);
  };

  const handleChangeDate = async (val: any) => {
    dispatch(setDate(val));
    const params: any = {
      page: 1,
      per_page: pageSize,
      keyword: search,
    };
    if (val) {
      params.start_date = dayjs(val[0]).format("YYYY-MM-DD");
      params.end_date = dayjs(val[1]).format("YYYY-MM-DD");
    }
    await dispatch(storeAction.search(params)).unwrap();
  };

  const handleDownload = async () => {
    try {
      const params: Record<string, string> = {};
      if (date) {
        params.start_date = dayjs(date[0]).format("YYYY-MM-DD");
        params.end_date = dayjs(date[1]).format("YYYY-MM-DD");
      }
      if (search) params.keyword = search;
      const { data } = await RecapServices.downloadRecap(params);
      downloader(data, "Recap", ".xlsx");
    } catch (err) {
      message.error("Donwload error!");
      console.log(err);
    }
  };

  return (
    <>
      <Card className="mb-3">
        <Row justify="space-between">
          <Col span={18}>
            <Row gutter={10} justify="start">
              <Col span={12}>
                <Input.Search
                  loading={isLoadingSearch}
                  value={search}
                  placeholder="Search"
                  onChange={handleChange}
                  onSearch={() => sendBackendRequest(search)}
                  enterButton
                />
              </Col>
              <Col span={12}>
                <RangePicker
                  value={date}
                  onChange={handleChangeDate}
                  format={"DD/MM/YYYY"}
                />
              </Col>
            </Row>
          </Col>

          <Col span={6}>
            <Row justify="end">
              <Button
                onClick={handleDownload}
                type="primary"
                icon={<RetweetOutlined />}
              >
                Download
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ActionTable;
